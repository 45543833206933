import { useTranslation } from "react-i18next";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";

import { addLeadingZeros } from "utils/utils";

import "./PatientWidget.scss";

const PatientWidget = ({ patient, hintText, onClick, className = "" }) => {
  const { t /*, i18n*/ } = useTranslation();
  const historyBadges = [];

  if (patient?.patient_history)
    patient["patient_history"].forEach((item, idx) => {
      if (item.description)
        historyBadges.push(
          <Badge key={idx} className="bg-slate-400" value={item.description} />,
        );
    });
  if (historyBadges.length === 0) historyBadges.push("-");

  return (
    <div
      className={`patient-widget my-5 flex flex-col justify-center ${className}`}
    >
      {!patient && (
        <div className="no-patient flex flex-col center">
          {hintText && <p className="mb-2 text-sm">{hintText}</p>}
          <Button className="btn-primary" onClick={onClick}>
            {t("Select patient")}
          </Button>
        </div>
      )}
      {!patient || (
        <div className="grid p-4 font-sm">
          <div className="flex items-center mb-2">
            <label>{t("Patient ID")}</label>
            <p>{addLeadingZeros(patient.patient.id, 5)}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("First name")}</label>
            <p>{patient.patient.first_name}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("Last name")}</label>
            <p>{patient.patient.last_name}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("Date of birth")}</label>
            <p>{patient.patient.birth_date}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("Age")}</label>
            <p>{patient.patient.age}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("Sex")}</label>
            <p>{t(patient.patient.gender)}</p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("Body weight")}</label>
            <p>
              {t("number", { val: patient.patient.body_weight }) || "-"}{" "}
              {t(patient.patient.body_unit)}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <label>{t("eGFR")}</label>
            <p>
              {t("number", { val: patient.patient.kidney_function }) || "-"}{" "}
              {patient.patient.kidney_function && t("ml/min/1.73m²")}
            </p>
          </div>
          <div className="flex items-center mb-2 whitespace-nowrap">
            <label>{t("Medical history")}</label>
          </div>
          <div className="history flex self-start mb-2 text-sm">
            {historyBadges}
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientWidget;
