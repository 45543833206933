import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Badge from "components/Badge/Badge";
import Button from "components/Form/Button/Button";
import ReviewForm from "components/ReviewForm/ReviewForm";
import TreatmentPlansTable from "components/TreatmentPlansTable/TreatmentPlansTable";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import NewHomeSessionModal from "components/NewHomeSessionModal/NewHomeSessionModal";

import { getSession, updateSession, deleteSession } from "api/sessions";
import { getTreatmentPlans, deleteTreatmentPlan } from "api/treatmentPlans";
import { reviewSession, submitReviewedSession } from "api/reviews";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

import { isStudent, isTeacher, isDraft } from "utils/utils";

const SingleSession = ({ readOnly = false }) => {
	const { sessionId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { authData } = useAuth();
	const { userData, canI } = useUser();

	const [session, setSession] = useState([]);
	const [refreshSession, setRefreshSession] = useState(false);
	const [treatmentPlans, setTreatmentPlans] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [treatmentPlanId, setTreatmentPlanId] = useState();

	// modals - states
	const [showEditNameModal, setShowEditNameModal] = useState(false);
	const [showSubmitSessionModal, setShowSubmitSessionModal] = useState(false);
	const [showSubmitReviewModal, setShowSubmitReviewModal] = useState(false);
	const [showDeleteTPModal, setShowDeleteTPModal] = useState(false);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);

	/**
	 * treatment plans table - actions
	 *
	 *  these are the actions allowed depending on the role
	 *  the actions allowed depending on the status of each item
	 *  are defined in PatientsTable
	 */

	const rowActions = [];

	if (canI("readAll", "SingleTreatmentPlan"))
		rowActions.push({
			name: "readAll",
			icon: "ri-eye-line",
			command: handleViewTP,
			title: t("View session"),
		});

	if (canI("read", "SingleTreatmentPlan"))
		rowActions.push({
			name: "read",
			icon: "ri-eye-line",
			command: handleViewTP,
		});

	if (canI("update", "SingleTreatmentPlan"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEditTP,
			title: t("Edit treatment plan"),
		});
	/*
	if (canI("review", "SingleTreatmentPlan"))
		rowActions.push({
			name: "review",
			icon: "ri-check-double-line",
			command: handleReviewTP,
			title: t("Review session"),
		});
	*/
	if (canI("delete", "SingleTreatmentPlan"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDeleteTP,
			title: t("Delete treatment plan"),
		});

	const updateDescription = async (description) => {
		const response = await updateSession({
			token: authData.access_token,
			sessionId: sessionId,
			description: description,
			classId: 0,
			statusId: 1,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200) {
			setSession({ ...session, description: description });
		}
	};

	// table - TPs action handlers

	function handleViewTP(id) {
		navigate(`/session/${sessionId}/treatment/${id}`);
	}

	function handleEditTP(id) {
		navigate(`/session/${sessionId}/treatment/${id}`);
	}
	/*
	function handleReviewTP(id) {
		// navigate(`/session/${sessionId}/treatment/${id}`);
		console.log("review ", id);
	}
	*/
	function handleDeleteTP(id) {
		setTreatmentPlanId(id);
		setShowDeleteTPModal(true);
	}

	const handleDeleteTPConfirmed = async () => {
		setShowDeleteTPModal(false);
		const response = await deleteTreatmentPlan({
			token: authData.access_token,
			treatmentPlanId: treatmentPlanId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Treatment plan deleted!"));
			setRefreshTable(true);
		}
	};

	// aside session action handlers

	const handleSubmitReviewForm = async ({ grade, notes }) => {
		console.log("reviewing session...", { grade, notes });
		const response = await reviewSession({
			token: authData.access_token,
			sessionId: sessionId,
			gradeId: grade.value,
			notes: notes,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Session review saved!"));
			setRefreshTable(true);
			setShowSubmitReviewModal(false);
		}
	};

	const handleSubmitSession = async () => {
		setShowSubmitSessionModal(false);
		const response = await updateSession({
			token: authData.access_token,
			sessionId: session.id,
			description: session.description,
			classId: session.class_id,
			statusId: 2,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200) {
			toast.success(t("Session submitted!"));
			setSession({ ...session, status: "Submitted" });
		}
	};

	const handleSubmitReview = async () => {
		console.log("submitting review...");
		const response = await submitReviewedSession({
			token: authData.access_token,
			sessionId: sessionId,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else if (response.status === 200) {
			toast.success(t("Review submitted!"));
			setRefreshSession(true);
			setShowSubmitReviewModal(false);
		}
	};

	const handleDeleteSessionConfirmed = async () => {
		setShowDeleteSessionModal(false);
		const response = await deleteSession(authData.access_token, sessionId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
			console.log(response);
		} else {
			toast.success(t("Session deleted!"));
			navigate("/sessions");
		}
	};

	const handleClose = () => {
		navigate("/sessions");
	};

	useEffect(() => {
		console.log("getting the session...");
		(async () => {
			const sessionData = await getSession(authData.access_token, sessionId);
			if (sessionData === "ERR_BAD_REQUEST") {
				toast.error(t(sessionData.response.data.detail));
				console.log(sessionData);
			} else {
				setSession(sessionData);
				setRefreshSession(false);
			}
		})();
	}, [authData.access_token, sessionId, refreshSession, t]);

	useEffect(() => {
		(async () => {
			const data = await getTreatmentPlans(authData.access_token, sessionId);
			if (data === "ERR_BAD_REQUEST") {
				toast.error(t(data.response.data.detail));
				console.log(data);
			} else {
				setTreatmentPlans(data);
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, sessionId, refreshTable, t]);

	return (
		<>
			<div className="single-session h-full flex items-stretch">
				<div className="view flex-auto">
					<div className="header">
						<h1>
							{session.description}
							<Button
								className={`inline-action ${
									session.status !== "Draft" ||
									session.session_type === "Class-Study"
										? "hidden disabled"
										: ""
								}`}
								title={t("Edit sesison name")}
								onClick={() => setShowEditNameModal(true)}
							>
								<i className="ri-pencil-line"></i>
							</Button>
							<Badge className="ml-4" value={session.status} />
						</h1>
						<div className="flex mb-5">
							<div className="description max-w-4xl">
								<p className="message__note">
									<i className="ri-book-open-line"></i>
									{session.status === "Draft" &&
										t(
											"You can add, edit or delete treatments plan or submit the session for evaluation. Once submitted, the session cannot be edited anymore nor deleted.",
										)}
									{session.status === "Submitted" &&
										t(
											"You can review this submitted session, including treatment plans and prescriptions. Once the review is submitted, the session cannot be edited anymore nor deleted.",
										)}
									{session.status === "Reviewed" &&
										t(
											"This session was already reviewed. Editing or deleting it is not possible anymore.",
										)}
								</p>
							</div>
						</div>
					</div>
					<div className="content">
						<h2>{t("List of treatment plans")}</h2>
						<TreatmentPlansTable
							data={treatmentPlans}
							actions={rowActions}
							newAction={() => navigate(`/session/${sessionId}/treatment/`)}
							newActionLabel={t("Add treatment plan")}
							readOnly={
								!(canI("create", "SingleTreatmentPlan") && isDraft(session))
							}
						/>
					</div>
				</div>
				<aside className="aside ml-6 flex flex-col">
					{/*<h3 className="mt-4 mb-5">{t("Session information")}</h3>*/}
					<div className="py-4 border-b border-slate-300">
						<div className="table text-sm">
							<div className="table-row">
								<strong>{t("Session ID")}</strong>
								<div>
									{sessionId}
									<span className="ml-2">
										{session.class_id ? (
											<Badge value={"Class-Study"} />
										) : (
											<Badge value={"Self-Study"} />
										)}
									</span>
								</div>
							</div>
							{/*
							<div className="table-row">
								<strong>{t("Date created")}</strong>
								<div>{formatDate(session.created_at)}</div>
							</div>
							<div className="table-row">
								<strong>{t("Date updated")}</strong>
								<div>{formatDate(session.updated_at)}</div>
							</div>
							<div className="table-row">
								<strong>{t("Status")}</strong>
								<div>{session.status}</div>
							</div>
							*/}
							{userData.user_role !== "Student" && (
								<div className="table-row">
									<strong>{t("Author")}</strong>
									<div>{session.creator}</div>
								</div>
							)}
						</div>
					</div>

					{/*<h3 className="mt-4 mb-5 float-left">{t("Session review")}</h3>*/}
					{session && (
						<ReviewForm
							className="py-4 border-b border-slate-300"
							target={session}
							readOnly={
								!(
									userData.user_role === "Teacher" &&
									session.status === "Submitted"
								)
							}
							buttonCaption={t("Review session")}
							onClose={() => setRefreshSession(true)}
							onSubmit={handleSubmitReviewForm}
						/>
					)}

					{isStudent(userData) &&
						session.status === "Draft" &&
						session.session_type === "Self-Study" && (
							<>
								<h3 className="mt-4 mb-4">{t("Request teachers review")}</h3>
								<p className="message__note text-sm mb-5">
									<i className="ri-book-open-line"></i>
									{t(
										"If you want to get your home session reviewed by a teacher, click on the button below. After submission, a sessions cannot be edited or deleted.",
									)}
								</p>
								<div className="actions mb-5 flex justify-end">
									{session.status !== "Submitted" && (
										<Button
											className="btn-secondary min-w-[50%]"
											onClick={() => setShowSubmitSessionModal(true)}
										>
											{t("Request review")}
										</Button>
									)}
									{session.status === "Submitted" && (
										<p className="text-sm my-2">
											{t("The session awaits teacher's review")}
										</p>
									)}
								</div>
							</>
						)}

					{isStudent(userData) && session.session_type === "Class-Study" && (
						<>
							<h3 className="mt-4 mb-4">{t("Submit session")}</h3>
							<p className="text-sm mb-5">
								{t(
									"When you are done, submit your session by click on the button below. After submission, a sessions cannot be edited or deleted.",
								)}
							</p>
							<div className="actions mb-5 flex justify-end">
								{session.status !== "Submitted" && (
									<Button
										className="btn-secondary min-w-[50%]"
										onClick={() => setShowSubmitSessionModal(true)}
									>
										{t("Submit session")}
									</Button>
								)}
								{session.status === "Submitted" && (
									<p className="text-sm my-2 w-full text-center">
										{t("The session awaits teacher's review")}
									</p>
								)}
							</div>
						</>
					)}

					{isTeacher(userData) && (
						<>
							<h3 className="mt-4 mb-4">{t("Submit review")}</h3>
							<p className="text-sm mb-5">
								{t(
									"By clicking the button below the review will be saved, and the student will be informed about the new status of his session. After saving the review no changes can be made anymore.",
								)}
							</p>
							<div className="actions mb-5 flex justify-end">
								<Button
									className={`btn-secondary min-w-[50%] ${
										["Draft", "Reviewed"].includes(session.status) && "disabled"
									}`}
									onClick={() => setShowSubmitReviewModal(true)}
								>
									{t("Submit review")}
								</Button>
							</div>
						</>
					)}
					<div className="actions mt-auto flex justify-center">
						<Button
							className={`btn min-w-[auto] py-0 bg-transparent font-xl text-primary ${
								session.status !== "Draft" && "disabled"
							}`}
							title={t("Delete session")}
							onClick={() => setShowDeleteSessionModal(true)}
						>
							<i className="ri-delete-bin-line"></i>
						</Button>
						<Button className="btn-primary w-full" onClick={handleClose}>
							{t("Close session")}
						</Button>
					</div>
				</aside>
			</div>

			{showEditNameModal && (
				<NewHomeSessionModal
					description={session.description}
					onClose={() => setShowEditNameModal(false)}
					onSubmit={(description) => updateDescription(description)}
				/>
			)}

			{showSubmitSessionModal && (
				<ConfirmationModal
					title={t("Confirm submit")}
					message={t(
						"You are going to submit the session. After that changes are not possible anymore. Are you sure you want to submit the session?",
					)}
					yesMessage={t("Submit")}
					onClose={() => setShowSubmitSessionModal(false)}
					onSubmit={handleSubmitSession}
				/>
			)}

			{showSubmitReviewModal && (
				<ConfirmationModal
					title={t("Confirm submit")}
					message={t(
						"You are going to submit the review. After that changes are not possible anymore. Are you sure you want to submit the review?",
					)}
					yesMessage={t("Submit")}
					onClose={() => setShowSubmitReviewModal(false)}
					onSubmit={handleSubmitReview}
				/>
			)}

			{showDeleteTPModal && (
				<ConfirmationModal
					title={t("Delete treatment plan?")}
					message={`${t(
						"Are you sure you want to delete the treatment plan with the ID",
					)} ${treatmentPlanId}?`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteTPModal(false)}
					onSubmit={handleDeleteTPConfirmed}
				/>
			)}

			{showDeleteSessionModal && (
				<ConfirmationModal
					title={t("Delete session?")}
					message={`${t(
						"Are you sure you want to delete the session with the ID",
					)} ${sessionId}? ${t("This operation cannot be undone.")}`}
					yesMessage={t("Delete")}
					onClose={() => setShowDeleteSessionModal(false)}
					onSubmit={handleDeleteSessionConfirmed}
				/>
			)}
		</>
	);
};

SingleSession.displayName = "SingleSession";

export default SingleSession;
