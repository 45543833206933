import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";

const Actions = ({ row, actions = [] }) => {
  const getClassByAction = (row, action, readOnly = false) => {
    const classList = ["action"];

    switch (action.name) {
      case "read":
        if (row.status_id === 1) classList.push("hidden");
        break;
      case "edit":
        if (row.status_id !== 1) classList.push("hidden");
        break;
      case "review":
        if (row.status_id !== 2) classList.push("hidden");
        break;
      case "delete":
        if (row.status_id !== 1) classList.push("disabled");
        break;
      default:
    }

    return classList.join(" ");
  };

  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={getClassByAction(row, action)}
            onClick={() => action.command(row)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const PrescriptionsTable = ({
  data,
  actions,
  newAction,
  newActionLabel,
  readOnly = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      /*
      {
        Header: t("ID"),
        accessor: "id",
      },
      */
      {
        Header: t("Product"),
        accessor: "drug_name",
      },
      /*
      {
        Header: "Frequency",
        accessor: "dose_frequency_number",
        cssClass: "text-center",
        disableSortBy: true,
      },
      {
        Header: "Time unit",
        accessor: "dose_frequency",
        cssClass: "text-center",
        disableSortBy: true,
      },
      */
      {
        Header: t("Frequency"),
        accessor: (row) => `${row.dose_frequency_number} ${row.dose_frequency}`,
        cssClass: "text-center",
        disableSortBy: true,
      },
      /*
      {
        Header: "Dose",
        accessor: "dose",
        cssClass: "text-center",
        disableSortBy: true,
      },
      {
        Header: "Unit",
        accessor: "dose_unit",
        cssClass: "text-center",
        disableSortBy: true,
      },
      */
      {
        Header: t("Dose"),
        accessor: (row) => `${row.dose} ${row.dose_unit_description}`,
        cssClass: "text-center",
        disableSortBy: true,
      },
      {
        Header: t("Route"),
        accessor: "route_of_administration",
        disableSortBy: true,
      },
      {
        Header: t("Notes"),
        accessor: "notes",
        cssClass: "text-center truncate-20",
        disableSortBy: true,
      },
      {
        Header: t("Grade"),
        accessor: "grade",
        disableSortBy: true,
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => {
          if (value) return <Badge className="badge-status" value={value} />;
        },
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        cssClass: "text-center min-cell-width pr-5",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Actions row={original} actions={actions} />
        ),
      },
    ],
    [actions, t],
  );

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        newAction={readOnly === true ? "" : newAction}
        newActionLabel={newActionLabel}
        {...rest}
      />
    </>
  );
};

export default PrescriptionsTable;
