import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import NewClassModal from "components/NewClassModal/NewClassModal";
import EditClassModal from "components/EditClassModal/EditClassModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import InformationModal from "components/InformationModal/InformationModal";
import ClassesTable from "components/ClassesTable/ClassesTable";

import { createClass, getClasses, updateClass, deleteClass } from "api/classes";

import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";

const Classes = () => {
	// modals - states
	const [showNewClassModal, setShowNewClassModal] = useState(false);
	const [showEditClassModal, setShowEditClassModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSendClassIdModal, setShowSendClassIdModal] = useState(false);

	// hooks
	const { authData } = useAuth();
	const { canI } = useUser();
	const { t } = useTranslation();

	// table - states
	const [tableData, setTableData] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const [classId, setClassId] = useState();
	const [classDesc, setClassDesc] = useState();
	const [classIsActive, setClassIsActive] = useState();
	const [classIsPublished, setClassIsPublished] = useState();

	// table - actions
	const rowActions = [];

	if (canI("update", "Class"))
		rowActions.push({
			name: "edit",
			icon: "ri-pencil-line",
			command: handleEdit,
			title: t("Edit class"),
		});

	if (canI("delete", "Class"))
		rowActions.push({
			name: "delete",
			icon: "ri-delete-bin-line",
			command: handleDelete,
			title: t("Delete class"),
		});

	const createNewClass = async (description) => {
		const response = await createClass({
			token: authData.access_token,
			description: description,
			isPublished: 0,
		});
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(response.class_id);
			setRefreshTable(true);
			setShowSendClassIdModal(true);
		}
	};

	// table - action handlers

	function handleEdit(id, description, is_active, is_published) {
		setClassId(id);
		setClassDesc(description);
		setClassIsActive(is_active);
		setClassIsPublished(is_published);
		setShowEditClassModal(true);
	}

	function handleDelete(id) {
		setClassId(id);
		setShowDeleteModal(true);
	}

	const deleteConfirmed = async () => {
		setShowDeleteModal(false);
		const response = await deleteClass(authData.access_token, classId);
		if (response.code === "ERR_BAD_REQUEST") {
			toast.error(t(response.response.data.detail));
		} else {
			setClassId(null);
			setRefreshTable(true);
		}
	};

	const applyFilters = (data) => {
		// only return active elements
		return data.filter((object) => object.is_active);
	};

	useEffect(() => {
		(async () => {
			const tableData = await getClasses(authData.access_token);

			if (tableData.code === "ERR_BAD_REQUEST") {
				toast.error(t(tableData.response.data.detail));
			} else {
				setTableData(applyFilters(tableData));
				setRefreshTable(false);
			}
		})();
	}, [authData.access_token, refreshTable, t]);

	return (
		<>
			<div className="view">
				<div className="header">
					<h1>{t("Classes")}</h1>
					<div className="flex mb-5">
						<div className="description max-w-4xl">
							<p className="message__note">
								<i className="ri-book-open-line"></i>
								{t(
									"List of all the classes and their statuses. Teachers can define a new class and send the Class-ID to the students. The view is only visible for teachers and admin.",
								)}
							</p>
						</div>
					</div>
				</div>
				<div className="content">
					<h2>{t("List of classes")}</h2>
					<ClassesTable
						data={tableData}
						actions={rowActions}
						newAction={() => setShowNewClassModal(true)}
						newActionLabel={t("Create new class")}
					/>
				</div>
			</div>

			{showNewClassModal && (
				<NewClassModal
					onClose={() => setShowNewClassModal(false)}
					onSubmit={(description) => {
						setClassDesc(description);
						createNewClass(description);
						setShowSendClassIdModal(true);
					}}
				/>
			)}

			{showSendClassIdModal && (
				<InformationModal
					title={t("Class created!")}
					onClose={() => setShowSendClassIdModal(false)}
					onSubmit={() => setShowSendClassIdModal(false)}
				>
					<div className="flex center mb-12">
						<p>
							{t("Class ID")}: <strong>{classId}</strong>
						</p>
					</div>
					<p>
						{t(
							`The class was successfully created! You have to send the students the class ID manually.`,
						)}
					</p>
				</InformationModal>
			)}

			{showEditClassModal && (
				<EditClassModal
					classId={classId}
					classDesc={classDesc}
					classIsActive={classIsActive}
					classIsPublished={classIsPublished}
					onClose={() => setShowEditClassModal(false)}
					onSubmit={(classDesc, classIsActive, classIsPublished) => {
						updateClass(
							authData.access_token,
							classId,
							classDesc,
							classIsActive,
							classIsPublished,
						);
						setClassId(null);
						setRefreshTable(true);
					}}
				/>
			)}

			{showDeleteModal && (
				<ConfirmationModal
					title={t("Disable class")}
					message=<span>
						{t("Are you sure you want to disable the following class?")} ID:{" "}
						{classId}
					</span>
					yesMessage={t("Disable")}
					onClose={() => {
						setClassId(null);
						setShowDeleteModal(false);
					}}
					onSubmit={deleteConfirmed}
				/>
			)}
		</>
	);
};

Classes.displayName = "Classes";

export default Classes;
