import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Button from "components/Form/Button/Button";
import Select from "components/Form/Select/Select";
import Toggle from "components/Form/Toggle/Toggle";

const EditClassModal = ({ user, roleId, isBlocked, onClose, onSubmit }) => {
	const { t } = useTranslation();
	const [toggleState, setToggleState] = useState(isBlocked);

	const roles = [
		{ value: 1, label: t("Administrator") },
		{ value: 2, label: t("Student") },
		{ value: 3, label: t("Teacher") },
	];
	const [selectState, setSelectState] = useState(
		roles.find((obj) => obj.value === roleId),
	);

	const handleSubmit = () => {
		console.log(selectState);
		onSubmit(selectState.value, toggleState);
		setToggleState("");
		onClose();
	};

	return (
		<Modal title={`${t("Edit user")} ${user}`} onClose={onClose}>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="mt-4">
					{t(
						"You can block the user. A blocked user won't be able to login again. The login data and sessions will not be deleted.",
					)}
				</p>
				<Select
					id="user-block"
					className="mt-4"
					label={t("Role")}
					options={roles}
					value={selectState}
					allowNone={false}
					onChange={(value) => setSelectState(value)}
				/>
				<Toggle
					id="user-block"
					className="mt-4"
					label={t("blocked")}
					value={toggleState}
					onChange={(e) => setToggleState(e.target.checked)}
				/>
				<div className="flex center mt-8">
					<Button className="btn" onClick={onClose}>
						{t("Cancel")}
					</Button>
					<Button className="btn-primary">{t("Save")}</Button>
				</div>
			</form>
		</Modal>
	);
};

export default EditClassModal;
