import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import { getDate } from "utils/utils";

const Actions = ({ row, actions = [] }) => {
  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className="action"
            title={action.title}
            onClick={() =>
              action.command(
                row.id,
                row.description,
                row.is_active,
                row.is_published,
              )
            }
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const ClassesTable = ({ data, actions, newAction, newActionLabel }) => {
  const { t /*, i18n*/ } = useTranslation();

  const columns = useMemo(
    () =>
      [
        {
          Header: t("ID"),
          accessor: "id",
          cssClass: "min-cell-width",
        },
        {
          Header: t("Description"),
          accessor: "description",
        },
        {
          Header: t("Author"),
          accessor: "creator",
          cssClass: "min-cell-width",
          //disableGlobalFilter: true,
        },
        /*{
        Header: t("Active"),
        accessor: "is_active",
        cssClass: "text-center",
      },*/
        {
          Header: t("Status"),
          accessor: "is_published",
          cssClass: "text-center min-cell-width",
          Cell: ({ cell: { value } }) =>
            value ? t("published") : t("not published"),
        },
        {
          Header: t("Date created"),
          accessor: "created_at",
          cssClass: "text-center datetime min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        {
          Header: t("Date modified"),
          accessor: "updated_at",
          cssClass: "text-center datetime min-cell-width",
          Cell: ({ cell: { value } }) => getDate(value),
        },
        actions.length > 0 && {
          Header: t("Actions"),
          accessor: "accessor",
          disableSortBy: true,
          cssClass: "text-center min-cell-width pr-5",
          Cell: ({ row: { original } }) =>
            actions && <Actions row={original} actions={actions} />,
        },
      ].filter((item) => item !== false),
    [actions, t],
  );

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        searchLabel={t("Search by ID or description")}
        searchFields={["id", "description"]}
        searchGlobal={true}
        newAction={newAction}
        newActionLabel={newActionLabel}
      />
    </>
  );
};

export default ClassesTable;
