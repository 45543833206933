import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "components/Form/Input/Input";
import Password from "components/Form/Input/Password";
import DateInput from "components/Form/DateInput/DateInput";
import Select from "components/Form/Select/Select";
import Combo from "components/Form/Combo/Combo";
import Textarea from "components/Form/Textarea/Textarea";
import Button from "components/Form/Button/Button";

export default function Settings() {
  const { t } = useTranslation();

  const selectOptions = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
  ];

  const comboOptions = [
    { value: 1, label: "A 1" },
    { value: 2, label: "Option 2" },
  ];
  const [input, setInput] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [combo, setCombo] = useState(null);
  const [select, setSelect] = useState("");
  const [textarea, setTextarea] = useState("");

  return (
    <>
      <div className="my-sessions view">
        <div className="header">
          <h1>{t("Settings")}</h1>
          <div className="flex mb-5">
            <div className="description max-w-4xl">
              <p className="message__note">
                <i className="ri-book-open-line"></i>
                {t(
                  "In future versions this view will include administrative settings like setting the lagnauge, locales, taking the system offline for maintenence.",
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="my-8 ml-6 w-96">
          <h2>Fields & Select elements</h2>
          <Input
            className="my-8"
            label="Input"
            value={input}
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Input
            className="my-8"
            label="Input"
            value={input}
            adornment="kg/m³"
            required
            onChange={(e) => setInput(e.target.value)}
          />
          <Password
            className="my-8"
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <DateInput
            className="my-8"
            label="Date"
            value={date}
            // pattern="[0-9]{2}-[0-9]{2}-(19|20)\d{2}"
            // maxLength="10"
            // minLength="10"
            placeholder={t("DD-MM-YYYY")}
            required
            onChange={(e) => setDate(e.target.value)}
          />
          <Combo
            className="my-8"
            label="Combo"
            options={comboOptions}
            value={combo}
            onChange={setCombo}
          />
          <Select
            className="my-8"
            label="Select"
            options={selectOptions}
            value={select}
            onChange={setSelect}
          />
          <Textarea
            className="my-8"
            label="Textarea"
            value={textarea}
            onChange={(e) => setTextarea(e.target.value)}
          />
        </div>
        <div className="my-8 ml-6">
          <Button className="btn-primary" onClick={(e) => alert("clicked!")}>
            Primary
          </Button>
          <Button onClick={(e) => alert("clicked!")}>Standard</Button>
          <Button className="disabled" onClick={(e) => alert("clicked!")}>
            Disabled
          </Button>
        </div>
        <div className="mt-8">
          <h3>Output</h3>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Input</strong>
                </td>
                <td>{input}</td>
              </tr>
              <tr>
                <td>
                  <strong>Password</strong>
                </td>
                <td>{password}</td>
              </tr>
              <tr>
                <td>
                  <strong>Date</strong>
                </td>
                <td>{date}</td>
              </tr>
              <tr>
                <td>
                  <strong>Combo</strong>
                </td>
                {combo && (
                  <td>
                    [{combo.value}] "{combo.label}"
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <strong>Select</strong>
                </td>
                <td>{select.label}</td>
              </tr>
              <tr>
                <td>
                  <strong>Textarea</strong>
                </td>
                <td>{textarea}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

Settings.displayName = "Settings";
