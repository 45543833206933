import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Form/Button/Button";
import Table from "components/Table/Table";
import Badge from "components/Badge/Badge";
import { getDate } from "utils/utils";

const Actions = ({ row, actions = [], readOnly = false }) => {
  const getClassByAction = (row, action, readOnly) => {
    // console.log(row.status, action.name, readOnly);
    const classList = ["action"];

    switch (action.name) {
      case "readAll":
        break;
      case "read":
        if (row.status === "Draft") classList.push("hidden");
        break;
      case "edit":
        if (row.status !== "Draft") classList.push("hidden");
        break;
      case "review":
        if (row.status !== "Submitted") classList.push("disabled");
        break;
      case "delete":
        if (row.status !== "Draft") classList.push("disabled");
        break;
      default:
    }

    return classList.join(" ");
  };

  return (
    <div className="actions-wrapper">
      {actions.map(function (action, idx) {
        return (
          <Button
            key={idx}
            className={getClassByAction(row, action, readOnly)}
            onClick={() => action.command(row.id)}
            title={action.title}
          >
            <i className={action.icon}></i>
          </Button>
        );
      })}
    </div>
  );
};

const SessionTable = ({
  data,
  actions,
  newAction,
  newActionLabel,
  readOnly = false,
}) => {
  const { t /*, i18n*/ } = useTranslation();
  const columns = useMemo(
    () => [
      /*{
        Header: "ID",
        accessor: "id",
      },*/
      {
        Header: t("Status"),
        accessor: "status",
        cssClass: "text-sm",
        Cell: ({ cell: { value } }) => (
          <Badge className="badge-status" value={value} />
        ),
      },
      {
        Header: t("Description"),
        accessor: "description",
      },
      {
        Header: t("Author"),
        accessor: "creator",
        cssClass: "min-cell-width",
      },
      {
        Header: t("Type"),
        accessor: "session_type",
        cssClass: "text-center text-sm min-cell-width",
        Cell: ({ cell: { value } }) => <Badge value={value} />,
      },
      {
        Header: t("Class ID"),
        accessor: "class_id",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => (value ? value : "-"),
      },
      {
        Header: t("Class author"),
        accessor: "class_creator",
        cssClass: "min-cell-width",
        Cell: ({ cell: { value } }) => (value ? value : "-"),
      },
      {
        Header: t("Date created"),
        accessor: "created_at",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => getDate(value),
      },
      {
        Header: t("Grade"),
        accessor: "grade",
        cssClass: "text-center text-sm min-cell-width",
        Cell: ({ cell: { value } }) => {
          if (value) return <Badge className="badge-status" value={value} />;
        },
      },
      /*{
        Header: t("Reviewer"),
        accessor: "reviewer",
      },*/
      {
        Header: t("Date graded"),
        accessor: "reviewed_at",
        cssClass: "text-center min-cell-width",
        Cell: ({ cell: { value } }) => getDate(value),
      },
      {
        Header: t("Actions"),
        accessor: "accessor",
        cssClass: "text-center min-cell-width pr-5",
        disableSortBy: true,
        Cell: ({ row: { original } }) => (
          <Actions row={original} actions={actions} readOnly={readOnly} />
        ),
      },
    ],
    [actions, readOnly, t],
  );

  return (
    <>
      <Table
        className="bg-white"
        columns={columns}
        data={data}
        searchLabel={t("Search by description")}
        searchColumns={["description"]}
        newAction={readOnly === true ? "" : newAction}
        newActionLabel={newActionLabel}
      />
    </>
  );
};

export default SessionTable;
