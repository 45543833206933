import { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal/Modal";
import Input from "components/Form/Input/Input";
import Button from "components/Form/Button/Button";

const NewHomeSessionModal = (props) => {
	const { t } = useTranslation();
	const [sessionDesc, setSessionDesc] = useState(props?.description || "");

	const handleSubmit = () => {
		props.onSubmit(sessionDesc);
		setSessionDesc("");
		props.onClose();
	};

	return (
		<Modal
			title={props?.description ? t("Edit description") : t("New home session")}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onSubmit={props.onSubmit}
		>
			<form className="flex flex-col gap-4 w-full" onSubmit={handleSubmit}>
				<p className="mt-4">{t("Enter a description for this session")}</p>
				<Input
					id="session-id"
					className="mt-4"
					label={t("Session description")}
					type="text"
					name="session-desc"
					value={sessionDesc}
					onChange={(e) => setSessionDesc(e.target.value)}
					autoFocus
					required
				/>
				<div className="flex center mt-8">
					<Button className="btn-primary">
						{props?.description
							? t("Save description")
							: t("Create new home session")}
					</Button>
				</div>
			</form>
		</Modal>
	);
};

export default NewHomeSessionModal;
